import * as React from "react"

import { Checkbox, Form, Input ,Button  , Divider , Tooltip} from 'antd';
import {login} from '../../api/request'
import { Link } from "gatsby";

const Login = (props) => {
    const {submit} =props
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        console.log('Received values of form: ', values );
        // submit()
        login(values.username , values.password).then(res=>{
            console.log(res);
            if(res?.data?.token) {
                let a = document.createElement("a");
                a.href = `https://app.wegrow.cc/?token=${res.data.token}&is_register=${res.data.is_register}`
                a.click();
            }
        })
    };
    
    return (
        <>
            <div className=" pb-5 flex items-baseline justify-between">
                <h3 className="text-2xl text-[#2E2F33]">登录</h3>
                <div className="text-[#8B8E9A]">
                    没有账号,
                    <Link to="/register" className="text-[#576AA4] cursor-pointer">去注册</Link>
                </div>
            </div>
            <Form
                onFinish={onFinish}
                name="basic"
                layout="vertical"
                // form={form}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                // autoComplete="off"
            >
            
                <Form.Item
                    label="邮箱"
                    name="username"
                    required={false}
                    rules={[{ required: true, message: ' ' ,type:'email'}]}
                >
                    <Input className="w-[100%]" />
                </Form.Item>

                <Form.Item
                    label="密码"
                    name="password"
                    required={false}
                    rules={[{ required: true, message: ' ' }]}
                >
                    <Input.Password className="w-[100%]" />
                </Form.Item>
                
                {/* <Form.Item noStyle valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Form.Item noStyle name="remember" valuePropName="checked">
                        <Checkbox  className=" bg-cyan-[#56BF8B]">10天内自动登录</Checkbox>
                    </Form.Item>
                </Form.Item> */}
                <Form.Item >
                    <Tooltip placement="bottom" title='请联系管理员修改密码' >
                        <span className="text-[12px] text-blue-500 cursor-pointer float-right underline">忘记密码</span>
                    </Tooltip>
                </Form.Item>

            <Form.Item className=" ">
                <Button className="w-[100%] mt-4" type="primary" htmlType="submit">
                    登录
                </Button>
            </Form.Item>

            </Form>

            {/* <Divider plain>
                <span className=" text-[#B9BECD] text-[12px]">快捷登录</span>
            </Divider>

            <ul className="flex flex-row flex-nowrap ml-2 mr-2 xl:ml-9 xl:mr-9">
                {
                    LinkIcons.map(icon=>{
                        return (
                            <li className="basis-1/6 m-2"> <img className="w-[100%] cursor-pointer opacity-50 hover:opacity-100" src={icon.icon} alt="" /> </li>
                        )
                    })
                }
            </ul> */}
        </>
    )
}


export default Login