/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Resister from "../../components/login-componts/register";
import LoginGo from "../../components/login-componts/login";
import { Popover } from '@headlessui/react'
import LoginLayout from '../../components/login-componts/index'



const Login = ()=>{
  const [IsLogin , useIsLogin] = useState(true)

  return (
    <LoginLayout>
      <LoginGo />
    </LoginLayout>
  )
}
export const Head = () => <Seo  title="Home" />

export default Login